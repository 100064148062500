import React, { Component } from 'react';

import Layout from 'src/components/layout';
import RiskManagementMeasuresSection from '../../components/risk-management-measures-section';
import Disclaimer from '../../components/disclaimer';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import { TranslatedBlock } from 'src/components/language';
import { TranslatableText } from '../../components/language';

import RiskminIStock from '../../images/riskmin-iStock.png';
import AudioIcon from '../../images/audio-icon.svg';
import PatientCareGiver from '../../images/patientcaregiver.svg';
import ArrowDown from '../../images/arrow-down.svg';

import './index.scss';

class PatientPage extends Component {
  static contextType = AppStateContext;
  state = {
    isAudioVisible: false,
    isDropdownVisible: false,
    isTranscriptSectionVisible: false,
  };

  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('risk-management-measures');
  }

  toggleDropdown = () => {
    this.setState({ isDropdownVisible: !this.state.isDropdownVisible });
  };

  setAsstestLangEN = () => {
    this.context.setAssetsLang('english');
    this.setState({ isDropdownVisible: false });
  };

  setAsstestLangFR = () => {
    this.context.setAssetsLang('french');
    this.setState({ isDropdownVisible: false });
  };

  openTranscriptSection = e => {
    e.preventDefault();
    this.setState({ isTranscriptSectionVisible: true });
    setTimeout(() => {
      document.getElementById('transcript-section').scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 0);
  };

  render() {
    let title = {
      english: 'Safety Information',
      french: 'Mesures d’atténuation des risques',
    };
    return (
      <Layout title={title} showNavbar={true} className="patient">
        <section className="page-header">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-12 col-md-5 risk-minimization py-lg-0 py-5">
                <TranslatedBlock language="english">
                  <h1>Risk Management Measures</h1>
                  <h2>Safety Information</h2>
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <h1>Mesures d’atténuation des risques</h1>
                  <h2>Renseignements sur l’innocuité</h2>
                </TranslatedBlock>
              </div>
              <div className="col-12 col-md-7 patient-guide">
                <img src={RiskminIStock} alt="Risk Management Measures Icon" />
              </div>
            </div>
          </div>
        </section>

        <div className="container pb-2">
          <div className="row">
            <div className="col-12 py-5 text-center">
              <TranslatedBlock language="english">
                <h2 className="h1 mb-0">VABYSMO Patient/Caregiver Guide</h2>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <h2 className="h1 mb-0">Guide du patient/soignant sur VABYSMO</h2>
              </TranslatedBlock>
            </div>
          </div>
        </div>

        <section className="risk-management-measures-download-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 py-5">
                <TranslatedBlock language="english">
                  <p className="text-white">Select your language</p>
                  <div className="dropdown-wrapper">
                    <button type="button" className="dropdown-button" onClick={this.toggleDropdown}>
                      {this.context.getAssetsLang() === 'english' ? 'English' : 'French'}

                      <span>
                        <img src={ArrowDown} alt="Assets toggler" />
                      </span>
                    </button>
                    {this.state.isDropdownVisible ? (
                      <div className="dropdown-select shadow">
                        <a onClick={this.setAsstestLangEN}>English</a>
                        <hr className="m-0" />
                        <a onClick={this.setAsstestLangFR}>French</a>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <p className="text-white">Sélectionnez la langue</p>
                  <div className="dropdown-wrapper">
                    <button type="button" className="dropdown-button" onClick={this.toggleDropdown}>
                      {this.context.getAssetsLang() === 'english' ? 'Anglais' : 'Français'}

                      <span>
                        <img src={ArrowDown} alt="Assets toggler" />
                      </span>
                    </button>
                    {this.state.isDropdownVisible ? (
                      <div className="dropdown-select shadow">
                        <a onClick={this.setAsstestLangEN}>Anglais</a>
                        <hr className="m-0" />
                        <a onClick={this.setAsstestLangFR}>Français</a>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </TranslatedBlock>
              </div>
              <div className="col-12 col-md-8 py-5 px-0">
                <div className="row audio-listen">
                  <div className="col-12 col-md-6 pdf">
                    <img src={PatientCareGiver} alt="Patient/Caregiver Guide Icon" className="pdf-img" />
                    <TranslatedBlock language="english">
                      {this.context.getAssetsLang() === 'english' ? (
                        <a
                          href="https://www.rochecanada.com/documents/vabysmo/Vabysmo_RiskMin_PatientCarerGuide_EN.pdf"
                          className="pdf-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View here
                        </a>
                      ) : (
                        <a
                          href="https://www.rochecanada.com/documents/vabysmo/Vabysmo_RiskMin_PatientCarerGuide_FR.pdf"
                          className="pdf-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View here
                        </a>
                      )}
                    </TranslatedBlock>
                    <TranslatedBlock language="french">
                      {this.context.getAssetsLang() === 'english' ? (
                        <a
                          href="https://www.rochecanada.com/documents/vabysmo/Vabysmo_RiskMin_PatientCarerGuide_EN.pdf"
                          className="pdf-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          CONSULTER
                        </a>
                      ) : (
                        <a
                          href="https://www.rochecanada.com/documents/vabysmo/Vabysmo_RiskMin_PatientCarerGuide_FR.pdf"
                          className="pdf-link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          CONSULTER
                        </a>
                      )}
                    </TranslatedBlock>
                  </div>
                  <div className="col-12 col-md-6 audio">
                    <img src={AudioIcon} alt="Audio Icon" />
                    <TranslatableText
                      dictionary={{
                        english: 'An audio version of the Risk Management Measures guide that you can download',
                        french:
                          'Vous pouvez télécharger une version audio du guide sur les mesures d’atténuation des risques.',
                      }}
                    />
                    <button className="listen" onClick={() => this.setState({ isAudioVisible: true })}>
                      <TranslatableText dictionary={{ english: 'Listen Now', french: 'ÉCOUTER' }} />
                    </button>
                  </div>

                  <div className="col-12 col-md-6 offset-md-6 text-center buttons">
                    <TranslatedBlock language="english">
                      {this.state.isAudioVisible ? (
                        <div className="audio-el">
                          {/* eslint-disable jsx-a11y/media-has-caption */}
                          {this.context.getAssetsLang() === 'english' ? (
                            <audio
                              controls
                              autoPlay
                              src="/audio/Vabysmo-Patient_Caregiver_Guide_RMT-Audio_File-8643E23v.mp3"
                            >
                              Your browser does not support the
                              <code>audio</code> element.
                            </audio>
                          ) : (
                            <audio
                              controls
                              autoPlay
                              src="/audio/Vabysmo-Patient_Caregiver_Guide_RMT-Audio_File-8643F23v.mp3"
                            >
                              Your browser does not support the
                              <code>audio</code> element.
                            </audio>
                          )}
                          {/* eslint-enable jsx-a11y/media-has-caption */}
                        </div>
                      ) : (
                        ''
                      )}
                      {this.context.getAssetsLang() === 'english' ? (
                        <a
                          className="text-uppercase btn btn-primary mt-5 mb-2 purple"
                          target="_blank"
                          onClick={this.openTranscriptSection}
                        >
                          view the transcript
                        </a>
                      ) : (
                        <a
                          className="text-uppercase btn btn-primary mt-5 mb-2 purple"
                          target="_blank"
                          onClick={this.openTranscriptSection}
                        >
                          view the transcript
                        </a>
                      )}

                      {this.context.getAssetsLang() === 'english' ? (
                        <a
                          className="text-uppercase btn btn-primary mt-4 purple"
                          rel="noopener noreferrer"
                          href="/pdf/Vabysmo_script_Patient_Caregiver_Guide-transcript for 8643E23v.rtf"
                          download
                        >
                          download the transcript
                        </a>
                      ) : (
                        <a
                          className="text-uppercase btn btn-primary mt-4 purple"
                          rel="noopener noreferrer"
                          href="/pdf/Vabysmo_script_Patient_Caregiver_Guide-transcript for 8643F23v.rtf"
                          download
                        >
                          download the transcript
                        </a>
                      )}
                    </TranslatedBlock>
                    <TranslatedBlock language="french">
                      {this.state.isAudioVisible ? (
                        <div className="audio-el">
                          {/* eslint-disable jsx-a11y/media-has-caption */}
                          {this.context.getAssetsLang() === 'english' ? (
                            <audio
                              controls
                              autoPlay
                              src="/audio/Vabysmo-Patient_Caregiver_Guide_RMT-Audio_File-8643E23v.mp3"
                            >
                              Your browser does not support the
                              <code>audio</code> element. FR
                            </audio>
                          ) : (
                            <audio
                              controls
                              autoPlay
                              src="/audio/Vabysmo-Patient_Caregiver_Guide_RMT-Audio_File-8643F23v.mp3"
                            >
                              Your browser does not support the
                              <code>audio</code> element. FR
                            </audio>
                          )}
                          {/* eslint-enable jsx-a11y/media-has-caption */}
                        </div>
                      ) : (
                        ''
                      )}
                      {this.context.getAssetsLang() === 'english' ? (
                        <a
                          className="text-uppercase btn btn-primary mt-5 mb-2 purple"
                          target="_blank"
                          href="#"
                          onClick={this.openTranscriptSection}
                        >
                          Afficher la transcription
                        </a>
                      ) : (
                        <a
                          className="text-uppercase btn btn-primary mt-5 mb-2 purple"
                          target="_blank"
                          href="#"
                          onClick={this.openTranscriptSection}
                        >
                          Afficher la transcription
                        </a>
                      )}

                      {this.context.getAssetsLang() === 'english' ? (
                        <a
                          className="text-uppercase btn btn-primary mt-4 purple"
                          rel="noopener noreferrer"
                          href="/pdf/Vabysmo_script_Patient_Caregiver_Guide-transcript for 8643E23v.rtf"
                          download
                        >
                          Télécharger la transcription
                        </a>
                      ) : (
                        <a
                          className="text-uppercase btn btn-primary mt-4 purple"
                          rel="noopener noreferrer"
                          href="/pdf/Vabysmo_script_Patient_Caregiver_Guide-transcript for 8643F23v.rtf"
                          download
                        >
                          Télécharger la transcription
                        </a>
                      )}
                    </TranslatedBlock>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="transcript-section" id="transcript-section">
          {this.state.isTranscriptSectionVisible ? (
            <>
              <div className="container mb-5 pt-3">
                <div className="row">
                  <div className="col-12 pt-5 pb-4">
                    {this.context.getAssetsLang() === 'english' ? (
                      <>
                        <p>
                          Welcome to your guide to VABYSMO (faricimab), for the treatment of neovascular wet age related
                          macular degeneration (AMD) or diabetic macular edema (DME).
                        </p>
                        <p>
                          This material was developed by Hoffmann-La Roche Limited as part of the risk management plan
                          for VABYSMO. This material is not intended for promotional use.
                        </p>
                        <p>
                          This guide provides important safety information to assist in your understanding of the risks
                          associated with VABYSMO.
                        </p>
                        <p>
                          You can use this guide to learn more about your condition, and what you can expect from your
                          treatment with VABYSMO. This guide is also for loved ones and supporters of people living with
                          wet AMD or DME. For any further questions, you should contact your doctor.
                        </p>
                        <p>
                          To find the latest versions of this guide (including this audio version) and the Patient
                          Medication Information leaflet, you can scan a QR code or visit vabysmo.ca/rmt
                        </p>
                      </>
                    ) : (
                      <>
                        <p>
                          Bienvenue à votre guide sur VABYSMO (faricimab), pour le traitement de la dégénérescence
                          maculaire liée à l’âge (DMLA) néovasculaire (forme exsudative ou humide) ou le traitement de
                          l’œdème maculaire diabétique (OMD).
                        </p>
                        <p>
                          Ce document a été conçu par Hoffmann-La Roche Limited/Limitée dans le cadre du plan de gestion
                          des risques pour VABYSMO. Il n’est pas destiné à un usage promotionnel.
                        </p>
                        <p>
                          Ce guide contient des renseignements importants sur l’innocuité qui vous aideront à bien
                          comprendre les risques associés à l’emploi de VABYSMO.
                        </p>
                        <p>
                          Ce guide vous renseignera sur votre affection et sur ce que vous pouvez attendre de votre
                          traitement par VABYSMO. Il peut également être utile à vos proches et aux soignants de
                          personnes atteintes de DMLA de type humide ou d’OMD. Pour toute autre question, veuillez vous
                          adresser à votre médecin.
                        </p>
                        <p>
                          Pour obtenir la toute dernière version du Guide du patient sur VABYSMO (notamment cette
                          version audio) et le feuillet de renseignements destinés aux patients, vous pouvez balayer un
                          code QR ou visiter le www.vabysmo.ca/rmt.
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {this.context.getAssetsLang() === 'english' ? (
                <RiskManagementMeasuresSection
                  sectionNr="Section 1:"
                  sectionTitle="Summary of important safety information"
                >
                  <p>
                    Contact your doctor as soon as possible if you experience any of the following symptoms after
                    treatment with VABYSMO:
                  </p>
                  <ul>
                    <li>Sudden decrease in your vision</li>
                    <li>
                      Your vision gets blurred, or you can’t see as well as usual, including an increased number of
                      small particles in your vision
                    </li>
                    <li>Worsening redness of the eye, eye pain, increased eye discomfort</li>
                    <li>Increased sensitivity to light.</li>
                  </ul>
                </RiskManagementMeasuresSection>
              ) : (
                <RiskManagementMeasuresSection
                  sectionNr="Section 1 :"
                  sectionTitle="Résumé des renseignements importants sur l’innocuité"
                >
                  <p>
                    Communiquez avec votre médecin dès que possible si vous présentez l’un des symptômes suivants après
                    votre traitement par VABYSMO :
                  </p>
                  <ul>
                    <li>une diminution soudaine de votre vision;</li>
                    <li>
                      une vision qui s’embrouille ou qui est moins nette que d’habitude, ou encore une augmentation du
                      nombre de petites particules dans votre champ de vision;
                    </li>
                    <li>une rougeur ou un inconfort à l’œil qui empirent, une douleur à l’œil;</li>
                    <li>une plus grande sensibilité à la lumière.</li>
                  </ul>
                </RiskManagementMeasuresSection>
              )}

              {this.context.getAssetsLang() === 'english' ? (
                <RiskManagementMeasuresSection sectionNr="Section 2:" sectionTitle="What is wet AMD?">
                  <p>
                    Wet AMD (neovascular or wet age-related macular degeneration) is a common cause of vision loss among
                    people aged 60 years and over. The more that wet AMD progresses, the larger the loss of vision
                    becomes. It is a long-term condition and needs ongoing care.
                  </p>
                  <p>
                    Wet AMD happens when abnormal vessels grow at the macula. Abnormal vessels can bleed and leak fluid
                    in the back of the eye, which causes swelling of the retina and vision loss.
                  </p>
                  <p>Wet AMD may affect one eye or both but only affects the central part of vision.</p>
                  <p>
                    The retina is the light-sensitive layer at the back of the eye. It records the images we see and
                    sends them to the brain.
                  </p>
                  <p>
                    The macula is the central part of the retina, responsible for our sharpest vision. This is used to
                    read, drive a car, recognize faces or colours, and see objects in fine detail.
                  </p>
                  <p>Symptoms of wet AMD may include:</p>
                  <ul>
                    <li>blurry or distorted vision, such as straight lines appearing wavy</li>
                    <li>objects appearing in the wrong shape or size</li>
                    <li>colours seem less bright</li>
                    <li>a dark, empty area in the center of vision</li>
                    <li>difficulty reading, driving, watching TV, or doing other daily tasks.</li>
                  </ul>
                </RiskManagementMeasuresSection>
              ) : (
                <RiskManagementMeasuresSection
                  sectionNr="Section 2 :"
                  sectionTitle="Qu’est-ce que la DMLA de type humide?"
                >
                  <p>
                    La DMLA de type humide (la dégénérescence maculaire liée à l’âge néovasculaire ou de forme
                    exsudative) est une cause fréquente de perte de vision chez les personnes âgées de 60 ans et plus. À
                    mesure que la DMLA de type humide progresse, la perte de vision devient plus importante. Il s’agit
                    d’une maladie chronique qui nécessite des soins continus.
                  </p>
                  <p>
                    La DMLA de type humide est causée par la croissance de vaisseaux sanguins anormaux dans la macula.
                    Les vaisseaux anormaux peuvent saigner et provoquer des fuites dans le fond de l’œil, causant ainsi
                    un gonflement de la rétine et la perte de vision.
                  </p>
                  <p>
                    La DMLA de type humide peut toucher un œil ou les deux yeux, mais elle n’affecte que la vision
                    centrale.
                  </p>
                  <p>
                    La rétine est la couche sensible à la lumière qui se trouve au fond de l’œil et qui capte les images
                    que nous voyons pour ensuite les transmettre au cerveau.
                  </p>
                  <p>
                    La macula est la partie centrale de la rétine qui est responsable de notre vision la plus précise.
                    Elle nous permet de lire, de conduire une voiture, de reconnaître les visages et les couleurs, et de
                    percevoir les fins détails des objets.
                  </p>
                  <p>Les symptômes de la DMLA de type humide peuvent comprendre :</p>
                  <ul>
                    <li>une vision trouble ou déformée (par exemple, les lignes droites paraissent ondulées);</li>
                    <li>une perception erronée de la forme ou de la taille des objets;</li>
                    <li>les couleurs qui paraissent moins vives;</li>
                    <li>la présence d’une zone sombre et vide au centre du champ de vision;</li>
                    <li>
                      de la difficulté à lire, à conduire, à regarder la télévision ou à accomplir d’autres tâches
                      quotidiennes.
                    </li>
                  </ul>
                </RiskManagementMeasuresSection>
              )}

              {this.context.getAssetsLang() === 'english' ? (
                <RiskManagementMeasuresSection sectionNr="Section 3:" sectionTitle="What is DME?">
                  <p>
                    People living with diabetes who have high blood sugar (glucose) levels over a long period of the
                    time are at risk of getting DME (diabetic macular edema).
                  </p>
                  <p>
                    DME is when the blood vessels in the retina are damaged which can leak fluid into the eye causing
                    swelling in the macula and vision loss.
                  </p>
                  <p>DME is the leading cause of vision loss in people living with diabetes.</p>
                  <p>
                    The retina is the light-sensitive layer at the back of the eye. It records the images we see and
                    sends them to the brain.
                  </p>
                  <p>
                    The macula is the central part of the retina, responsible for our sharpest vision. This is used to
                    read, drive a car, recognize faces or colours, and see objects in fine detail.
                  </p>
                  <p>Symptoms of DME may include:</p>
                  <ul>
                    <li>vision may be blurred or wavy</li>
                    <li>objects may change size</li>
                    <li>colours might appear dull or faded</li>
                    <li>seeing in bright or glaring light may be difficult</li>
                    <li>reading or driving may be difficult</li>
                    <li>gaps or dark spots may appear in your vision.</li>
                  </ul>
                </RiskManagementMeasuresSection>
              ) : (
                <RiskManagementMeasuresSection sectionNr="Section 3 :" sectionTitle="Qu’est-ce que l’OMD?">
                  <p>
                    Les personnes atteintes de diabète dont le taux de sucre sanguin (glycémie) reste élevé pendant une
                    longue période risquent de souffrir d'OMD (œdème maculaire diabétique).
                  </p>
                  <p>
                    L’OMD survient lorsque les vaisseaux sanguins de la rétine sont endommagés, ce qui peut causer un
                    écoulement de liquide à l’intérieur de l’œil et provoquer une enflure de la macula, ainsi qu’une
                    perte de vision.
                  </p>
                  <p>L’OMD est la principale cause de perte de vision chez les personnes atteintes de diabète.</p>
                  <p>
                    La rétine est la couche sensible à la lumière qui se trouve au fond de l’œil et qui capte les images
                    que nous voyons pour ensuite les transmettre au cerveau.
                  </p>
                  <p>
                    La macula est la partie centrale de la rétine qui est responsable de notre vision la plus précise.
                    Elle nous permet de lire, de conduire une voiture, de reconnaître les visages et les couleurs, et de
                    percevoir les fins détails des objets.
                  </p>
                  <p>Les symptômes de l’OMD peuvent comprendre :</p>
                  <ul>
                    <li>la vision peut être floue, avec une distorsion des lignes droites;</li>
                    <li>la taille des objets peut changer;</li>
                    <li>les couleurs peuvent paraître fades ou délavées;</li>
                    <li>la vision peut être difficile dans une lumière vive ou éblouissante;</li>
                    <li>la lecture et la conduite automobile peuvent être difficiles;</li>
                    <li>des zones vides ou des taches sombres peuvent apparaître dans le champ de vision</li>
                  </ul>
                </RiskManagementMeasuresSection>
              )}

              {this.context.getAssetsLang() === 'english' ? (
                <RiskManagementMeasuresSection sectionNr="Section 4:" sectionTitle="What is VABYSMO?">
                  <p>VABYSMO is an eye injection containing the active substance faricimab.</p>
                  <p>
                    Certain proteins in higher-than-normal levels can cause the growth of abnormal blood vessels or
                    cause damage to the normal vessels at the back of the eye. These abnormal vessels can leak fluid
                    into the eye causing swelling and vision loss.
                  </p>
                  <p>
                    By blocking these proteins, VABYSMO may help reduce the leakage and thereby maintain, or even
                    improve, your vision.
                  </p>
                  <p>
                    Your condition will be regularly monitored by your doctor to make sure the treatment is working for
                    you.
                  </p>
                </RiskManagementMeasuresSection>
              ) : (
                <RiskManagementMeasuresSection sectionNr="Section 4 :" sectionTitle="Qu’est-ce que VABYSMO?">
                  <p>
                    VABYSMO est un médicament qu’on injecte dans l’œil et qui contient une substance active appelée
                    faricimab.
                  </p>
                  <p>
                    Certaines protéines, lorsque leur concentration est plus élevée que la normale, peuvent entraîner la
                    croissance de vaisseaux sanguins anormaux ou endommager les vaisseaux normaux situés au fond de
                    l’œil. Ces vaisseaux anormaux peuvent laisser s’échapper un liquide, ce qui provoque de l’enflure et
                    une perte de vision.
                  </p>
                  <p>
                    En bloquant l’action de ces protéines, VABYSMO peut aider à réduire l’écoulement de liquide et donc
                    à maintenir, ou même à améliorer, la vision.
                  </p>
                  <p>
                    Votre médecin vérifiera régulièrement l’état de vos yeux pour s’assurer que le traitement est
                    efficace pour vous.
                  </p>
                </RiskManagementMeasuresSection>
              )}

              {this.context.getAssetsLang() === 'english' ? (
                <RiskManagementMeasuresSection sectionNr="Section 5:" sectionTitle="Treatment with VABYSMO">
                  <p className="h2">Preparing for Your Treatment Day</p>
                  <p>
                    Have a family member or friend take you to and from your appointment if possible. This will not only
                    help you in getting to your appointment, but might also support you in keeping all the information
                    given by your doctor.
                  </p>
                  <p>Avoid wearing makeup on the day of your injection.</p>
                  <p>Take dark glasses with you as your eyes may be sensitive to light after the injection.</p>
                  <p className="h2">On treatment day</p>
                  <p>Tell your doctor before having VABYSMO if:</p>
                  <ul>
                    <li>you have an infection in or around the eye</li>
                    <li>you have pain or redness in your eye (eye inflammation)</li>
                    <li>
                      you are allergic to faricimab or any of the non-medicinal ingredients of this medicine (acetic
                      acid 30%, D-sucrose, L-histidine, L-methionine, polysorbate 20, sodium chloride, water for
                      injections).
                    </li>
                  </ul>
                  <p className="h2">Before the Injection</p>
                  <p>
                    VABYSMO is injected into your eye, intravitreal injection, by a doctor experienced in giving eye
                    injections. Before the injection, your doctor will use a disinfectant eyewash to clean your eye
                    carefully to prevent infection.
                  </p>
                  <p>
                    It is understandable to worry about such an injection. Your doctor will give you an eye drop (local
                    anaesthetic) to numb the eye to reduce or prevent pain from the injection.
                  </p>
                  <p>Your eyelid will be kept open using a tool to stop you from blinking.</p>
                  <p className="h2">During the Injection</p>
                  <p>You will be awake.</p>
                  <p>You may feel pressure during the injection.</p>
                  <p className="h2">After the Injection</p>
                  <p>Ask your doctor if you should avoid any particular activities.</p>
                  <p>Use any treatment given to you by your eye doctor.</p>
                  <p>Try to rest your eyes for a few hours after the injection and as long as you need afterwards.</p>
                  <p>
                    You may have temporary vision problems (for example, blurred vision) after your injections with
                    Vabysmo. Do not drive or use machines as long as you have vision problems.
                  </p>
                </RiskManagementMeasuresSection>
              ) : (
                <RiskManagementMeasuresSection sectionNr="Section 5 :" sectionTitle="Traitement par VABYSMO">
                  <p className="h2">Préparation pour le jour du traitement</p>
                  <p>
                    Demandez à un membre de votre famille ou à un ami de vous accompagner pour aller à votre rendez-vous
                    et en revenir. En plus de vous aider à vous rendre au rendez-vous, cette personne pourra aussi vous
                    aider à retenir toute l’information que vous recevrez de votre médecin ce jour-là.
                  </p>
                  <p>Évitez de vous maquiller le jour de votre injection.</p>
                  <p>
                    Apportez des lunettes fumées, car vos yeux pourraient être sensibles à la lumière après l’injection.
                  </p>
                  <p className="h2">Le jour du traitement</p>
                  <p>Avant de recevoir VABYSMO, avisez votre médecin si :</p>
                  <ul>
                    <li>vous avez une infection à l’intérieur ou autour de l’œil;</li>
                    <li>vous avez une douleur ou une rougeur dans un œil (inflammation de l’œil);</li>
                    <li>
                      vous êtes allergique au faricimab ou à l’un des ingrédients non médicinaux (acide acétique à 30 %,
                      chlorure de sodium, eau pour injection, D-sucrose, L-histidine, L-méthionine, polysorbate 20) du
                      médicament.
                    </li>
                  </ul>
                  <p className="h2">Avant l’injection</p>
                  <p>
                    VABYSMO est injecté dans l’œil (injection intravitréenne) par un médecin ayant l’expérience des
                    injections oculaires. Avant l’injection, votre médecin va nettoyer minutieusement votre œil avec un
                    produit désinfectant pour prévenir l’infection.
                  </p>
                  <p>
                    Il est normal qu’une telle injection puisse vous inquiéter. Votre médecin mettra une goutte d’un
                    anesthésique local dans votre œil pour l’insensibiliser afin de réduire ou prévenir la douleur lors
                    de l’injection.
                  </p>
                  <p>
                    Votre paupière sera maintenue ouverte à l’aide d’un appareil qui vous empêchera de cligner de l’œil.
                  </p>
                  <p className="h2">Pendant l’injection</p>
                  <p>Vous ne serez pas endormi.</p>
                  <p>Vous sentirez peut-être une pression à l’intérieur de votre œil durant l’injection.</p>
                  <p className="h2">Après l’injection</p>
                  <p>Demandez à votre médecin s’il y a certaines activités que vous devriez éviter.</p>
                  <p>Administrez tout traitement prescrit par votre ophtalmologiste.</p>
                  <p>
                    Essayez de reposer vos yeux pendant les quelques heures suivant l’injection et aussi longtemps que
                    nécessaire par la suite.
                  </p>
                  <p>
                    Après une injection de Vabysmo, vous pourriez avoir des troubles de vision passagers (une vision
                    brouillée, par exemple). Évitez de conduire un véhicule ou d’utiliser des machines tant et aussi
                    longtemps que ces problèmes n’auront pas disparu.
                  </p>
                </RiskManagementMeasuresSection>
              )}

              {this.context.getAssetsLang() === 'english' ? (
                <RiskManagementMeasuresSection
                  sectionNr="Section 6:"
                  sectionTitle="How to Care for Yourself After Your VABYSMO Injection"
                >
                  <p>
                    After the injection, you will probably have blurred eyesight and small particles in your vision.
                    This is normal and should only last a few days.
                  </p>
                  <p>Sometimes, after having the VABYSMO intravitreal injection you may experience:</p>
                  <ul>
                    <li>Inflammation inside the eye.</li>
                    <li>An uncommon but severe infection inside the eye called “endophthalmitis”.</li>
                    <li>Tear or detachment of one of the layers at the back of the eye (the retina).</li>
                    <li>For wet AMD patients, a common tear called retinal pigment epithelial tear.</li>
                  </ul>
                  <p>
                    Contact your doctor immediately if you have any of the following, which are signs of allergic
                    reactions, inflammation, or infections:
                  </p>
                  <ul>
                    <li>sudden vision loss</li>
                    <li>worsening redness of the eye, eye pain, or increased eye discomfort</li>
                    <li>blurred or decreased vision</li>
                    <li>an increased number of small particles in your vision that don’t go away after a few days</li>
                    <li>increased sensitivity to light</li>
                  </ul>
                  <p>
                    It is important to follow the treatment schedule recommended by your doctor. Ask for your doctor’s
                    advice before stopping the treatment.
                  </p>
                </RiskManagementMeasuresSection>
              ) : (
                <RiskManagementMeasuresSection
                  sectionNr="Section 6 :"
                  sectionTitle="Soins recommandés à la suite de votre injection de VABYSMO"
                >
                  <p>
                    Après l’injection, vous aurez probablement une vision floue et de petites particules dans votre
                    champ de vision. Cela est normal et ne devrait durer que quelques jours.
                  </p>
                  <p>Il arrive parfois qu’une injection intravitréenne de VABYSMO entraîne les effets suivants :</p>
                  <ul>
                    <li>une inflammation à l’intérieur de l’œil;</li>
                    <li>une infection peu fréquente, mais grave, à l’intérieur de l’œil appelée « endophtalmie »;</li>
                    <li>
                      une déchirure ou un décollement de la rétine, une des couches qui recouvrent le fond de l’œil;
                    </li>
                    <li>
                      chez les patients atteints de DMLA humide, une déchirure de l’épithélium pigmentaire rétinien est
                      fréquente.
                    </li>
                  </ul>
                  <p>
                    Communiquez immédiatement avec votre médecin si vous présentez l’un ou l’autre des effets
                    secondaires suivants, qui sont des signes de réaction allergique, d’inflammation ou d’infection :
                  </p>
                  <ul>
                    <li>perte de vision soudaine;</li>
                    <li>rougeur ou inconfort à l’œil qui empirent, douleur dans l’œil;</li>
                    <li>vision trouble ou diminution de la vision;</li>
                    <li>
                      augmentation du nombre de petites particules dans le champ de vision, qui persistent pendant
                      plusieurs jours;
                    </li>
                    <li>plus grande sensibilité à la lumière.</li>
                  </ul>
                  <p>
                    Il est important de respecter le calendrier de traitement recommandé par votre médecin. Demandez
                    conseil à votre médecin avant de cesser le traitement.
                  </p>
                </RiskManagementMeasuresSection>
              )}

              {this.context.getAssetsLang() === 'english' ? (
                <RiskManagementMeasuresSection sectionNr="Section 7:" sectionTitle="Reporting of safety information">
                  <p>
                    If you get any side effects, talk to your doctor as soon as possible. This includes any possible
                    side effects not listed in the Patient Medication Information leaflet.
                  </p>
                  <p>You can also report side effects directly to Health Canada by:</p>
                  <ul>
                    <li>
                      Visiting the Web page on Adverse Reaction Reporting for information on how to report online, by
                      mail or by fax; or
                    </li>
                    <li>Calling toll-free at 1-866-234-2345</li>
                  </ul>
                  <p>By reporting side effects, you can help provide more information on this medicine.</p>
                  <p>
                    For additional safety information, please talk to your doctor and refer to the VABYSMO Patient
                    Medication Information leaflet.
                  </p>
                  <p>
                    To obtain additional copies of this material or to report an Adverse Event regarding a Roche
                    product, please contact the Roche Medical Information Department at 1-888-762-4388.
                  </p>
                  <p>
                    If you require this information in an accessible format, please contact Roche at 1-800-561-1759.
                  </p>
                </RiskManagementMeasuresSection>
              ) : (
                <RiskManagementMeasuresSection
                  sectionNr="Section 7 :"
                  sectionTitle="Déclaration des renseignements sur l’innocuité"
                >
                  <p>
                    Si vous subissez des effets secondaires, parlez-en à votre médecin dans les plus brefs délais. Cela
                    vaut également pour tout effet secondaire qui n’est pas mentionné dans le feuillet de renseignements
                    destinés aux patients.
                  </p>
                  <p>
                    Vous pouvez aussi signaler les effets secondaires directement à Santé Canada de l’une des deux
                    façons suivantes :
                  </p>
                  <ul>
                    <li>
                      en consultant la page Web sur la déclaration des effets secondaires pour savoir comment faire une
                      déclaration en ligne, par courrier ou par télécopieur; ou
                    </li>
                    <li>en composant le numéro sans frais 1-866-234-2345.</li>
                  </ul>
                  <p>
                    En signalant vos effets secondaires, vous contribuerez à fournir plus de renseignements sur ce
                    médicament.
                  </p>
                  <p>
                    Pour obtenir des renseignements supplémentaires sur l’innocuité de VABYSMO, veuillez vous adresser à
                    votre médecin et consulter le feuillet de renseignements destinés aux patients à l’adresse
                    vabysmo.ca/patient-info.
                  </p>
                  <p>
                    Pour obtenir des exemplaires supplémentaires de ce document ou pour signaler une manifestation
                    indésirable concernant un produit de Roche, veuillez communiquer avec le service d’Information sur
                    les médicaments de Roche au 1-888-762-4388.
                  </p>
                  <p>
                    Si vous souhaitez recevoir ces renseignements dans un format accessible, veuillez communiquer avec
                    Roche au 1-800-561-1759.
                  </p>
                </RiskManagementMeasuresSection>
              )}
              {this.context.getAssetsLang() === 'english' ? (
                <div className="container rpg-code">8643E23v</div>
              ) : (
                <div className="container rpg-code">8643F23v</div>
              )}
            </>
          ) : (
            ''
          )}
        </section>

        <Disclaimer />
      </Layout>
    );
  }
}

export default PatientPage;
