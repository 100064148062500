import React from 'react';

import './risk-management-measures-section.scss';

export default function RiskManagementMeasuresSection({ sectionNr, sectionTitle, children }) {
  return (
    <section className="risk-management-measures-section pb-5">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4">
            <div className="wrapper py-5">
              <p className="h2 text-white">{sectionNr}</p>
              <p className="h4 text-white">{sectionTitle}</p>
            </div>
          </div>
          <div className="col-12 col-md-8 py-4">{children}</div>
        </div>
      </div>
    </section>
  );
}
