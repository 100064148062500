import React from 'react';

import { TranslatableText } from './language';

import './disclaimer.scss';

export default () => (
  <div className="disclaimer container">
    <TranslatableText
      dictionary={{
        english:
          'This website is for educational purposes only. It is not intended to replace the advice and guidance of your healthcare team. If you have questions about VABYSMO treatment or your condition, talk to your eye doctor.',
        french:
          'Ce site Web est offert à des fins éducatives seulement. Il ne vise en aucun cas à remplacer l’opinion et les conseils de votre équipe soignante. Si vous avez des questions sur le traitement par VABYSMO ou sur votre état de santé, veuillez consulter votre ophtalmologiste.',
      }}
    />
    <br />
    <TranslatableText
      dictionary={{
        english:
          'For complete information on VABYSMO, consult the Patient Medication Information, or call 1-888-762-4388.',
        french:
          'Pour obtenir des renseignements complets sur VABYSMO, veuillez consulter la page Renseignements destinés aux patients ou composer le 1-888-762-4388.',
      }}
    />
  </div>
);
